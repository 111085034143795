import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDQNS-YSZRjw2f0QnY7-dlc0-Vpipmt5u4",
  authDomain: "livestream-80226.firebaseapp.com",
  projectId: "livestream-80226",
  storageBucket: "livestream-80226.appspot.com",
  messagingSenderId: "659060585706",
  appId: "1:659060585706:web:f6a9bf595933c608f25393"
};

try {
  firebase.initializeApp(firebaseConfig);
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}

export const auth = firebase.auth;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageTaskEvent = firebase.storage.TaskEvent;
export const storageTaskState = firebase.storage.TaskState;
