import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  TextField, Typography, List, ListItem, ListItemText, FormControl, 
  InputLabel, Select, FormLabel, FormControlLabel, Switch } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { db, storage } from '../lib/firebase';

const defaultInputs = {
  customer: '',
  colorPrimary: '',
  colorSecondary: '',
  colorTheme: 'dark',
  date: '',
  details: '',
  fontHeader: '',
  fontHeaderBasic: 'Arial',
  fontHeaderGoogle: false,
  fontHeaderSize: 34,
  fontText: '',
  fontTextBasic: 'Arial',
  fontTextGoogle: false,
  fontTextSize: 16,
  imageBase64: '',
  logo: '',
  schedule: '',
  slug: '',
  subtitle: '',
  title: '',
  vimeoChat: '',
  vimeoVideo: '',
  archived: false
}

/**
 * DROPZONEN TYYLITTELYT
 */
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#00000045',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#00000095',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const img = {
  display: 'block',
  width: 'auto',
  height: 70,
  maxWidth: '100%'
};

/**
 * DROPZONE docs -> https://react-dropzone.js.org/
 */
const FileDropzone = (props) => {
  const [fileError, setFileError] = useState("");
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxFiles: 1,
    maxSize: 20000000,
    onDrop: acceptedFiles => {
      props.handleFileSelect(acceptedFiles);
      props.setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    onDropRejected: () => {
      props.setEventData({...props.eventData, logo: '', imageBase64: ''});
      setFileError("Vain kuvatiedostot ovat sallittuja (.jpg, .png, .jfif)")
    },
    onDropAccepted: () => {
      setFileError("");
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const thumbs = (props.files.length === 0 && props.eventData.logo !== '')
    ? <ListItem
        dense
        disableGutters
      >
        <ListItemText 
          disableTypography  
          primary={<img src={props.eventData.logo} style={img} />}
        />
      </ListItem>
    : props.files.map(file => (
        <ListItem
          dense
          disableGutters
          key={file.name}
        >
          <ListItemText 
            disableTypography  
            primary={<img src={file.preview} style={img} />}
            secondary={<Typography variant="body2">{file.path} - {(file.size / 1000000).toFixed(1)} Mt</Typography>}
          />
        </ListItem>
      ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    props.files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [props.files]);

  return (
    <div 
      style={{ 
        border: "1px solid #00000042", 
        marginBottom: 16, 
        padding: 14, 
        borderRadius: 6, 
        backgroundColor: "#ffffff99",
        marginTop: 2
      }}
    >
      <div {...getRootProps({ style })} >
        <input {...getInputProps()} />
        <Typography variant="body2" gutterBottom style={{ cursor: 'default', userSelect: "none" }}>Vedä kuvatiedosto tähän tai valitse painamalla</Typography>
        <Typography variant="caption" style={{ cursor: 'default', userSelect: "none" }}><em>(Vain *.jpg ja *.png -kuvatiedostot ovat sallittuja)</em></Typography>
      </div>
      
      <List dense style={{ marginTop: 8 }}>
        {thumbs}
      </List>

      <Typography color='error'><small>{fileError}</small></Typography>
    </div>
  );
}

const EventDialog = ({ open, setOpen, refresh, setRefresh, type, id, handleArchive }) => {
  const [error, setError] = useState(null);
  const [eventData, setEventData] = useState(defaultInputs);
  const [details, setDetails] = useState(defaultInputs.details);
  const [schedule, setSchedule] = useState(defaultInputs.schedule);
  const [loading, setLoading] = useState(false);
  const [fileErr, setFileErr] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getData = async() => {
      try {
        const doc = await db.collection('events').doc(id).get();
        setEventData(doc.data());
        setDetails(doc.data().details);
        setSchedule(doc.data().schedule);
      } catch (err) {
        //console.log(err);
      }
    }
    if (id && type === 'edit') getData();
  }, [id]);

  const handleClose = useCallback(() => {
    setEventData(defaultInputs);
    setDetails(defaultInputs.details);
    setSchedule(defaultInputs.schedule);
    setFiles([]);
    setOpen(false)
  }, [open]);

  const handleSaveEvent = useCallback(async(event) => {
    event.preventDefault();

    try {
      const base64 = eventData.imageBase64;
      delete eventData.imageBase64;
      setLoading(true);
      setError(null);

      let docId = ''

      if (type === 'add') {
        eventData.created = new Date();
        // TODO: tehtävä tarkastus, onko slug jo olemassa!
        const data = await db.collection('events').add(eventData);
        docId = data.id;
      } 

      if (type === 'edit' && id) {
        eventData.edit = new Date();
        await db.collection('events').doc(id).update(eventData);
        docId = id;
      }

      if (base64) {
        const uploadTask = storage.ref(`logos/${docId}/logo.png`).putString(base64, 'data_url', { contentType: 'image/png' });
        uploadTask.on('state_changed',
          () => {},
          () => {}, 
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(async(downloadURL) => {
              await db.collection('events').doc(docId).update({ logo: downloadURL});
            });
          }
        );
      }
      setEventData(defaultInputs);
      setDetails(defaultInputs.details);
      setSchedule(defaultInputs.schedule);
      setFiles([]);
      setOpen(false);
      setRefresh(!refresh);
    } catch (err) {
      //console.log(err);
    } finally {
      setLoading(false);
    }
  }, [eventData]);

  const handleInputChange = useCallback((event) => {
    event.preventDefault();
    const key = event.target.name;
    const value = event.target.value.trimLeft();

    if (key === 'slug') {
      const slugValue = value.toString().replace(' ', '').replace(/[^0-9a-zA-Z-]/g, '').replace(/\s\s+/g, '').toLowerCase().trim();
      setEventData({...eventData, [key]: slugValue});
    } else {
      setEventData({...eventData, [key]: value});
    }
  }, [eventData]);

  const handleColorChange = useCallback((event) => {
    event.preventDefault();
    const key = event.target.name;
    let value = event.target.value.trim();
    if (value.search(/[^0-9a-fA-F#]\w*/g) === -1 && value.length <= 9) {
      const testFirst = /[#]/.test(value[0]);
      if (value.length > 0 && !testFirst) value = `#${value}`;
      setEventData({...eventData, [key]: value});
    }
  }, [eventData]);

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      720,
      720,
      'PNG',
      '70',
      0,
      uri => { resolve(uri); },
      'base64',
      512,
      512
    )
  });

  const handleFileSelect = async(props) => {
    if (props[0]?.type.split('/')[0] === 'image') {
      try {
        setFileErr(null);
        setLoading(true);
        const image = await resizeFile(props[0]);
        setEventData({
          ...eventData,
          logo: props[0].name,
          imageBase64: image,
        });
      } catch(err) {
        setFileErr('Vain kuvatiedostot ovat sallittuja');
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle className="admin-text-h2">
        {type === 'add' && (
          'Luo uusi tapahtuma'
        )}

        {type === 'edit' && (
          'Muokkaa tapahtumaa'
        )}
      </DialogTitle>
      
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5}>
            <Typography variant="body1" className="admin-text" gutterBottom>Asiakkaan tiedot</Typography>
            
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="text"
              name="customer"
              value={eventData.customer}
              onChange={handleInputChange}
              label="Asiakkaan nimi"
              placeholder="Asiakkaan nimi..."
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="text"
              name="logo"
              value={eventData.logo}
              onChange={handleInputChange}
              label="Logon sijainti"
              placeholder="Logon url..."
              InputLabelProps={{ shrink: true }}
            />

            <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 14 }}>Kuvatiedosto</Typography>
            <FileDropzone 
              handleFileSelect={handleFileSelect}
              setEventData={setEventData}
              eventData={eventData}
              fileErr={fileErr}
              setFileErr={setFileErr}
              files={files}
              setFiles={setFiles}
            />

            <Typography variant="body1" className="admin-text" gutterBottom style={{ marginTop: 16 }}>Tapahtuman värit ja fontit</Typography>

            <FormControl variant="outlined" margin="dense" style={{ width: '60%' }}>
              <InputLabel htmlFor="page-theme-color" shrink>Teema</InputLabel>
              <Select
                native
                value={eventData.colorTheme}
                label="Teema"
                labelWidth={98}
                onChange={(event) => setEventData({...eventData, colorTheme: event.target.value})}
                inputProps={{
                  name: 'colorTheme',
                  id: 'page-theme-color',
                }}
              >
                <option value='dark'>Tumma</option>
                <option value='light'>Vaalea</option>
              </Select>
            </FormControl>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                type="text"
                name="colorPrimary"
                value={eventData.colorPrimary}
                onChange={handleColorChange}
                label="Pääväri"
                placeholder="Esim. #0f3a33..."
                InputLabelProps={{ shrink: true }}
                style={{ width: '60%' }}
              />
              <div style={{ width: 40, height: 40, border: '1px solid #00000050', borderRadius: 4, backgroundColor: (eventData.colorPrimary.length >= 4) ? eventData.colorPrimary : 'inherit', marginTop: 6 }}></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                type="text"
                name="colorSecondary"
                value={eventData.colorSecondary}
                onChange={handleColorChange}
                label="Tekstin väri"
                placeholder="Esim. #fafafa..."
                InputLabelProps={{ shrink: true }}
                style={{ width: '60%' }}
              />
              <div style={{ width: 40, height: 40, border: '1px solid #00000050', borderRadius: 4, backgroundColor: (eventData.colorSecondary.length >= 4) ? eventData.colorSecondary : 'inherit', marginTop: 6 }}></div>
            </div>


            <FormControl style={{ marginTop: 12 }}>
              <FormLabel className="admin-text">Asiakkaan ja otsikon fontti</FormLabel>
              <FormControlLabel
                control={<Switch 
                  checked={eventData.fontHeaderGoogle} 
                  onChange={(event) => setEventData({...eventData, fontHeaderGoogle: !eventData.fontHeaderGoogle})} 
                  name="fontHeaderGoogle" 
                />}
                label="Käytä Google Fontteja"
              />
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {eventData.fontHeaderGoogle 
                ? <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    type="text"
                    name="fontHeader"
                    value={eventData.fontHeader}
                    onChange={handleInputChange}
                    label="Google fontin nimi"
                    placeholder="Esim. Zen Dots"
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '60%' }}
                  />
                : <FormControl variant="outlined" margin="dense" style={{ width: '60%' }}>
                    <InputLabel htmlFor="font-header-basic" shrink>Fontin nimi</InputLabel>
                    <Select
                      native
                      value={eventData.fontHeaderBasic}
                      label="Fontin nimi"
                      labelWidth={98}
                      onChange={(event) => setEventData({...eventData, fontHeaderBasic: event.target.value})}
                      inputProps={{
                        name: 'fontHeaderBasic',
                        id: 'font-header-basic',
                      }}
                    >
                      <option value='Arial'>Arial</option>
                      <option value='Arial Narrow'>Arial Narrow</option>
                      <option value='Arial Black'>Arial Black</option>
                      <option value='Times New Roman'>Times New Roman</option>
                      <option value='Helvetica'>Helvetica</option>
                      <option value='Verdana'>Verdana</option>
                      <option value='Courier'>Courier</option>
                      <option value='Tahoma'>Tahoma</option>
                      <option value='Impact'>Impact</option>
                    </Select>
                  </FormControl>
              }
              <FormControl variant="outlined" margin="dense" style={{ width: '35%' }}>
                <InputLabel htmlFor="font-header-size" shrink>Koko (px)</InputLabel>
                <Select
                  native
                  value={eventData.fontHeaderSize}
                  label="Koko (px)"
                  labelWidth={98}
                  onChange={(event) => setEventData({...eventData, fontHeaderSize: Number(event.target.value)})}
                  inputProps={{
                    name: 'fontHeaderSize',
                    id: 'font-header-size',
                  }}
                >
                  <option value={8}>8</option>
                  <option value={10}>10</option>
                  <option value={12}>12</option>
                  <option value={14}>14</option>
                  <option value={16}>16</option>
                  <option value={18}>18</option>
                  <option value={20}>20</option>
                  <option value={22}>22</option>
                  <option value={24}>24</option>
                  <option value={26}>26</option>
                  <option value={28}>28</option>
                  <option value={30}>30</option>
                  <option value={32}>32</option>
                  <option value={34}>34</option>
                  <option value={36}>36</option>
                  <option value={38}>38</option>
                  <option value={40}>40</option>
                  <option value={46}>46</option>
                  <option value={52}>52</option>
                </Select>
              </FormControl>
            </div>
            
            <FormControl style={{ marginTop: 12 }}>
              <FormLabel className="admin-text">Alaotsikon ja tekstin fontti</FormLabel>
              <FormControlLabel
                control={<Switch 
                  checked={eventData.fontTextGoogle} 
                  onChange={(event) => setEventData({...eventData, fontTextGoogle: !eventData.fontTextGoogle})} 
                  name="fontTextGoogle" 
                />}
                label="Käytä Google Fontteja"
              />
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {eventData.fontTextGoogle 
                ?  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    type="text"
                    name="fontText"
                    value={eventData.fontText}
                    onChange={handleInputChange}
                    label="Google fontin nimi"
                    placeholder="Esim. Raleway"
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '60%' }}
                  />
                : <FormControl variant="outlined" margin="dense" style={{ width: '60%' }}>
                    <InputLabel htmlFor="font-text-basic" shrink>Fontin nimi</InputLabel>
                    <Select
                      native
                      value={eventData.fontTextBasic}
                      label="Fontin nimi"
                      labelWidth={98}
                      onChange={(event) => setEventData({...eventData, fontTextBasic: event.target.value})}
                      inputProps={{
                        name: 'fontTextBasic',
                        id: 'font-text-basic',
                      }}
                    >
                      <option value='Arial'>Arial</option>
                      <option value='Arial Narrow'>Arial Narrow</option>
                      <option value='Arial Black'>Arial Black</option>
                      <option value='Times New Roman'>Times New Roman</option>
                      <option value='Helvetica'>Helvetica</option>
                      <option value='Verdana'>Verdana</option>
                      <option value='Courier'>Courier</option>
                      <option value='Tahoma'>Tahoma</option>
                      <option value='Impact'>Impact</option>
                    </Select>
                  </FormControl>
              }

              <FormControl variant="outlined" margin="dense" style={{ width: '35%' }}>
                <InputLabel htmlFor="font-text-size" shrink>Koko (px)</InputLabel>
                <Select
                  native
                  value={eventData.fontTextSize}
                  label="Koko (px)"
                  labelWidth={95}
                  onChange={(event) => setEventData({...eventData, fontTextSize: Number(event.target.value)})}
                  inputProps={{
                    name: 'fontTextSize',
                    id: 'font-text-size',
                  }}
                >
                  <option value={8}>8</option>
                  <option value={10}>10</option>
                  <option value={12}>12</option>
                  <option value={14}>14</option>
                  <option value={16}>16</option>
                  <option value={18}>18</option>
                  <option value={20}>20</option>
                  <option value={22}>22</option>
                  <option value={24}>24</option>
                  <option value={26}>26</option>
                  <option value={28}>28</option>
                  <option value={30}>30</option>
                  <option value={32}>32</option>
                  <option value={34}>34</option>
                  <option value={36}>36</option>
                  <option value={38}>38</option>
                  <option value={40}>40</option>
                  <option value={46}>46</option>
                  <option value={52}>52</option>
                </Select>
              </FormControl>
            </div>
            {(eventData.fontHeaderGoogle || eventData.fontTextGoogle) && (
              <Typography variant="subtitle1" paragraph style={{ marginTop: 12 }}>
                <a href="https://fonts.google.com/" target="_blank">Selaile Google Fontteja</a>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Typography variant="body1" className="admin-text" gutterBottom>Tapahtuman tiedot</Typography>

            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="text"
              name="title"
              value={eventData.title}
              onChange={handleInputChange}
              label="Otsikko"
              placeholder="Tapahtuman otsikko..."
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="text"
              name="subtitle"
              value={eventData.subtitle}
              onChange={handleInputChange}
              label="Alaotsikko"
              placeholder="Tapahtuman alaotsikko..."
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="text"
              name="slug"
              value={eventData.slug}
              onChange={handleInputChange}
              label="Tapahtuman tunnus"
              placeholder="Tapahtuman tunnus..."
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              type="datetime-local"
              name="date"
              value={eventData.date}
              onChange={handleInputChange}
              label="Päivämäärä"
              InputLabelProps={{ shrink: true }}
            />
            <div style={{ marginTop: 0, marginBottom: 8 }}>
              <Typography variant="caption" gutterBottom style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 14 }}>Perustiedot</Typography>
              <CKEditor
                editor={ ClassicEditor }
                data={details}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDetails(data);
                  setEventData({...eventData, details: data});
                }}
              />
            </div>
            <div style={{ marginTop: 0, marginBottom: 8 }}>
              <Typography variant="caption" gutterBottom style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 14 }}>Ohjelma</Typography>
              <CKEditor
                editor={ ClassicEditor }
                data={schedule}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSchedule(data);
                  setEventData({...eventData, schedule: data});
                }}
              />
            </div>
            <TextField
              fullWidth
              multiline
              rows={7}
              rowsMax={10}
              variant="outlined"
              margin="dense"
              type="text"
              name="vimeoVideo"
              value={eventData.vimeoVideo}
              onChange={handleInputChange}
              label="Vimeo-videon iframe-koodi"
              placeholder="<iframe.....>........</iframe>"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              rowsMax={6}
              variant="outlined"
              margin="dense"
              type="text"
              name="vimeoChat"
              value={eventData.vimeoChat}
              onChange={handleInputChange}
              label="Vimeo-chatin iframe-koodi"
              placeholder="<iframe.....>........</iframe>"
              InputLabelProps={{ shrink: true }}
            />           
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {type === 'add' && (
          <Button className="admin-button" color="primary" onClick={handleSaveEvent} disabled={loading}>Tallenna tapahtuma</Button>
        )}

        {type === 'edit' && (
          <>
            <Button className="admin-button" variant="outlined" onClick={(event) => handleArchive(event, id)} disabled={loading}>Arkistoi tapahtuma</Button>
            <Button className="admin-button" color="primary" onClick={handleSaveEvent} disabled={loading}>Päivitä tiedot</Button>
          </>
        )}

        <Button className="admin-button" color="secondary" onClick={handleClose} disabled={loading}>Sulje ikkuna</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EventDialog;
