import './App.css';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import Admin from './components/Admin';
import LiveStream from './components/LiveStream';

const Main = () => {
  useEffect(() => {
    window.location.replace('https://www.filmbutik.fi/');
  }, [])

  return (<></>);
}

const App = () => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route exact path='/' component={Main} />
        <Route exact path='/admin'><Redirect to="/login/admin" /></Route>
        <Route exact path='/login'><Redirect to="/login/admin" /></Route>
        <Route exact path='/login/admin' component={Admin} />
        <Route exact path='/:streamSlug' component={LiveStream} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
