import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { auth } from '../lib/firebase';
import AdminLogin from './AdminLogin';
import AdminView from './AdminView';

const Admin = () => {
  const [authUser, setAuthUser] = useState(auth().currentUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth().onAuthStateChanged((user) => { 
      user 
        ? setAuthUser(user) 
        : setAuthUser(null);
    });
    setLoading(false);
  }, []);

  return (
    <Container maxWidth="md">
      <Helmet>
        <title>Admin | Livestreams | Filmbutik</title>
      </Helmet>
      {(!loading && authUser)
        ? <AdminView />
        : <AdminLogin />
      }
    </Container>
  );
}

export default Admin;
