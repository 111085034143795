import '../css/componentStyles.css';
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import WebFont from 'webfontloader';

const apiUrl = (process.env.NODE_ENV === 'development') 
  ? `${process.env.REACT_APP_FILMBUTIKLIVE_API_DEV}/api/streams`
  : `${process.env.REACT_APP_FILMBUTIKLIVE_API}/api/streams`;

const LiveStream = ({ match }) => {
  const { params: { streamSlug }, } = match;

  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fonts, setFonts] = useState(['Roboto', 'Poppins', 'Dela Gothic One']);
  const [headerFont, setHeaderFont] = useState('Poppins, Roboto, sans-serif');
  const [textFont, setTextFont] = useState('Poppins, Roboto, sans-serif');

  useEffect(() => {
    const getData = async() => {
      setLoading(true);
      setData({});
      try {
        const res = await fetch(`${apiUrl}/${streamSlug}`);
        const json = await res.json();
        if (res.ok) {
          const appFonts = ['Roboto', 'Poppins', 'Dela Gothic One'];

          if (json.data.fontHeaderGoogle && json.data.fontHeader.trim() !== '') {
            const hFont = json.data.fontHeader.replace(/['"´`]/g, '').trim();
            appFonts.push(hFont);
            setHeaderFont(`"${hFont}", Poppins, Roboto, sans-serif`);
          }
          if (!json.data.fontHeaderGoogle && json.data.fontHeaderBasic.trim() !== '') {
            const hFont = json.data.fontHeaderBasic.replace(/['"´`]/g, '').trim();
            setHeaderFont(`"${hFont}", Poppins, Roboto, sans-serif`);
          }

          if (json.data.fontTextGoogle && json.data.fontText.trim() !== '') {
            const tFont = json.data.fontText.replace(/['"´`]/g, '').trim();
            appFonts.push(tFont);
            setTextFont(`"${tFont}", Poppins, Roboto, sans-serif`);
          }
          if (!json.data.fontTextGoogle && json.data.fontTextBasic.trim() !== '') {
            const tFont = json.data.fontTextBasic.replace(/['"´`]/g, '').trim();
            setTextFont(`"${tFont}", Poppins, Roboto, sans-serif`);
          }

          setData(json.data);
          setFonts(appFonts);
        } else {
          setError(json.error);
        }
      } catch (err) {
        setError("Tietokantavirhe!");
      } finally {
        setLoading(false);
      }
    }
    if (streamSlug && (streamSlug !== 'login' && streamSlug !== 'admin')) getData();
  }, [streamSlug]);

  WebFont.load({
    google: {
      families: fonts
    }
  });

  return (
    <div 
      style={{ 
        backgroundColor: (data.colorTheme === 'light') ? '#FFFFFF' : (data.colorTheme === 'dark') ? '#212121' : 'inherit',
        minHeight: '100vh'
      }
    }>
      <Helmet>
        <title>
          {(data.title) ? `${data.title} |` : 'Livestream |'} {(data.customer) ? `${data.customer} |` : ''} Filmbutik 
        </title>
      </Helmet>
      
      <header>
        <Box
          padding={2}
          style={{ 
            backgroundColor: (data.colorPrimary) ? data.colorPrimary : (data.colorTheme === 'light') ? '#FFFFFF' : (data.colorTheme === 'dark') ? '#212121' : 'inherit',
            color: (data.colorSecondary) ? data.colorSecondary : (data.colorTheme === 'light') ? '#212121' : (data.colorTheme === 'dark') ? '#FFFAEF' : 'inherit',
            boxShadow: (data.colorPrimary) ? `0px 5px 7px ${data.colorPrimary}70` : (data.colorTheme === 'dark') ? `0px 5px 7px #212121` : '0 0 0 inherit',
            paddingBottom: 24,
            paddingTop: 20
          }}
        >
          {data.title && (
            <Container maxWidth='lg'>
              <Grid container spacing={1}>
                <Hidden smUp>
                  <Grid item xs={12} sm={12} md={12}>
                    {data.logo && (
                      <img 
                        src={data.logo} 
                        alt={`${data.customer}_logo`} 
                        style={{ maxHeight: 80 }}
                        draggable={false}
                      />
                    )}
                  </Grid>
                </Hidden>
                
                <Grid item xs={12} sm={7} md={8}>
                  <Typography 
                    variant="h4" 
                    component="h2" 
                    gutterBottom 
                    noWrap
                    style={{
                      fontFamily: headerFont, 
                      fontSize: (data.fontHeaderSize) ? Number(data.fontHeaderSize) : 'default' 
                    }}
                  >{data.customer}</Typography>
                  <Typography 
                    variant="h4" 
                    component="h1" 
                    gutterBottom
                    style={{
                      fontFamily: headerFont, 
                      fontSize: (data.fontHeaderSize) ? Number(data.fontHeaderSize) : 'default' 
                    }}
                  ><small>{data.title}</small></Typography>
                  <Typography 
                    variant="body1" 
                    component="p"
                    paragraph
                    style={{
                      fontFamily: textFont, 
                      fontSize: (data.fontTextSize) ? Number(data.fontTextSize) : 'default' 
                    }}
                  >{data.subtitle}</Typography>
                  <Typography 
                    variant="body1" 
                    component="p"
                    noWrap
                    style={{
                      fontFamily: textFont, 
                      fontSize: (data.fontTextSize) ? Number(data.fontTextSize) : 'default' 
                    }}
                  ><small>{(data.date) ? new Date(data.date).toLocaleString() : ''}</small></Typography>                  
                </Grid>

                <Hidden xsDown>
                  <Grid item xs={12} sm={5} md={4} style={{ textAlign: 'right' }}>
                    {data.logo && (
                      <img 
                        src={data.logo} 
                        alt={`${data.customer}_logo`} 
                        style={{ 
                          height: 100,
                          width: '100%',
                          objectPosition: 'right',
                          objectFit: 'scale-down'
                        }}
                        draggable={false}
                      />
                    )}
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          )}
        </Box>
      </header>

      <main 
        style={{
          paddingTop: 16,
          backgroundColor: (data.colorTheme === 'light') ? '#FFFFFF' : (data.colorTheme === 'dark') ? '#424242' : 'inherit'
        }}
      >
        <Container maxWidth='lg'>
          <Box padding={2}>
            {loading && (
              <Typography 
                variant="h6"
              >Tietoja haetaan...</Typography>
            )}

            {(!loading && error) && (
              <Typography 
                variant="h6"
              >{error}</Typography>
            )}

            {(!loading && error ===  null && 'title' in data) && (
              data.archived
                ? <Typography 
                    variant="h6"
                    color="inherit"
                    paragraph
                  >Tapahtuma ei ole enää saatavilla.</Typography>
                : <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.details
                      }}
                      style={{ 
                        marginBottom: 36,
                        color: (data.colorTheme === 'light') ? '#212121' : (data.colorTheme === 'dark') ? '#FFFAEF' : 'inherit',
                        fontFamily: textFont, 
                        fontSize: (data.fontTextSize) ? Number(data.fontTextSize) : 'initial'
                      }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8}>
                        {data.vimeoVideo?.length > 0 
                          ? <div
                              dangerouslySetInnerHTML={{ 
                                __html: data.vimeoVideo?.replace(/“/g, '"').replace(/”/g, '"')
                              }}
                              className="livestream-vimeo-video"
                            />
                          : <p
                              style={{
                                fontFamily: textFont, 
                                fontSize: (data.fontTextSize) ? Number(data.fontTextSize) : 'initial',
                                color: (data.colorTheme === 'light') ? '#212121' : (data.colorTheme === 'dark') ? '#FFFAEF' : 'inherit'
                              }}
                            >Tapahtumaan ei ole asetettu videostreamia...</p>   
                        }
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        {data.vimeoChat?.length > 0 && (
                          <div 
                            dangerouslySetInnerHTML={{
                              __html: data.vimeoChat?.replace(/“/g, '"').replace(/”/g, '"')
                            }}
                            className="livestream-vimeo-chat"
                          />
                        )}
                      </Grid>
                    </Grid>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.schedule
                      }}
                      style={{ 
                        marginTop: 48,
                        paddingBottom: 36,
                        color: (data.colorTheme === 'light') ? '#212121' : (data.colorTheme === 'dark') ? '#FFFAEF' : 'inherit',
                        fontFamily: textFont, 
                        fontSize: (data.fontTextSize) ? Number(data.fontTextSize) : 'initial'
                      }}
                    />
                  </>
            )}
          </Box>
        </Container>        
      </main>
      
      <footer
        className="livestream-footer"
        style={{ 
          backgroundColor: (data.colorPrimary) ? data.colorPrimary : (data.colorTheme === 'light') ? '#FFFFFF' : (data.colorTheme === 'dark') ? '#212121' : 'inherit',
          boxShadow: (data.colorPrimary) ? `0px -3px 5px ${data.colorPrimary}85` : (data.colorTheme === 'dark') ? `0px -3px 5px #212121` : '0 0 0 inherit',
        }}
      >
      </footer>
    </div>
  );
}

export default LiveStream;
