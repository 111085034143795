import { Box, Button, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { auth } from '../lib/firebase';

import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
  input: {
    background: `#FFFAEF`,
  },
}));

const AdminLogin = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = useCallback(async(event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    try {
      setLoading(true);
      setErrorMessage(null);
      await auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      setLoading(false);
      if (err.message) setErrorMessage(err.message);
    }
  }, [loading]);

  return (
    <Box padding={3}>
      <Typography variant="h5" component="h1" className="admin-title" style={{ marginBottom: 42 }}>Admin login</Typography>

      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          type="email"
          variant="filled"
          color="secondary"
          margin="normal"
          name="email"
          id="email"
          label="Sähköpostiosoite"
          className={classes.input}
        />
        <TextField
          fullWidth
          type="password"
          variant="filled"
          color="secondary"
          margin="normal"
          name="password"
          id="password"
          label="Salasana"
          className={classes.input}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={loading}
          style={{ marginTop: 24 }}
          className="admin-button"
          endIcon={<LockOpenIcon />}
        >Kirjaudu</Button>          
      </form>

      <Typography variant="subtitle1" color="error">{errorMessage}</Typography>
    </Box>
  );
}

export default AdminLogin;
