import '../css/componentStyles.css';
import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, 
  ListItemText, Tooltip, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { auth, db } from '../lib/firebase';
import EventDialog from './EventDialog';

import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShareIcon from '@material-ui/icons/Share';

const domainUrl = (process.env.NODE_ENV === 'development')
  ? process.env.REACT_APP_FILMBUTIKLIVE_DEV
  : process.env.REACT_APP_FILMBUTIKLIVE;

const AdminView = () => {
  const [error, setError] = useState(null);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventDialogType, setEventDialogType] = useState('add');
  const [eventId, setEventId] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getData = async() => {
      try {
        setLoading(true);
        setError(null);
        const data = await db.collection('events').where('archived', '!=', true).get();
        setEvents(data.docs);
      } catch (err) {
        setEvents([]);
        setError('Tietokantavirhe! Ota yhteyttä ylläpitoon.')
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [refresh]);

  const handleArchive = useCallback(async(event, id) => {
    event.preventDefault();

    try {
      await db.collection('events').doc(id).update({ archived: true });
      setRefresh(!refresh);
    } catch (err) {
      //console.log(err);
    }
  }, [events]);

  const handleLogout = useCallback(async(event) => {
    event.preventDefault();
    await auth().signOut();
  }, []);

  const openAddEventDialog = useCallback(async(event, type, id) => {
    event.preventDefault();
    setEventDialogOpen(true);

    if (type === 'add') {
      setEventDialogType('add');
    }
    if (type === 'edit') {
      setEventDialogType('edit');
      setEventId(id);
    }
  }, [eventDialogOpen]);

  return (
    <Box padding={2}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 48 }}>
        <Typography variant="h4" component="h1" className="admin-title">Hei, pääkäyttäjä.</Typography>
        <Button
          onClick={handleLogout}
          variant="outlined"
          color="inherit"
          size="small"
          endIcon={<ExitToAppIcon />}
          className="admin-button"
          //style={{ fontFamily: 'Poppins, Roboto, sans-serif'}}
        >Kirjaudu ulos</Button>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          <div className="add-button" onClick={(event) => openAddEventDialog(event, 'add', null)}>
            <Button 
              variant="text"
              size="large"
              endIcon={<AddIcon />}
              disabled
              className="admin-button"
            >Lisää uusi tapahtuma</Button>
          </div>
          <Divider variant="fullWidth" style={{ marginTop: 20 }} />        
        </Grid>
        
        <Grid item xs={12} sm={12} md={9}>
          {(loading) && (
            <Typography variant="body1" className="admin-text"><b>Haetaan tapahtumia...</b></Typography>
          )}

          {(!loading && events.length === 0) && (
            <Typography variant="body1" className="admin-text"><b>Ei tapahtumia...</b></Typography>
          )}

          {(!loading && error === null && events.length > 0) && (
            <List disablePadding>
              {events.map((e, i) => (
                <ListItem 
                  disableGutters
                  className="event-list"
                  key={i}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={3} md={4} style={{ overflow: 'hidden '}}>
                          <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center' }}>
                            {e.data().logo !== '' 
                              ? <img 
                                  src={e.data().logo}
                                  alt='logo'
                                  style={{ width: '100%', height: 'auto', maxWidth: 140 }}
                                />
                              : <Typography variant="body2" className="admin-text"><small>Ei logoa</small></Typography>
                            }
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={9} md={8} style={{ overflow: 'hidden '}}>
                          <Typography variant="subtitle1" component="h3" className="admin-text-h3">{e.data().customer}</Typography>
                          <Typography variant="body1" component="h2" className="admin-text-h2" gutterBottom>{e.data().title}</Typography>
                          <Typography variant="body2" className="admin-text" gutterBottom>{e.data().date ? new Date(e.data().date).toLocaleString() : 'Tapahtuman päivämäärää ei ole asetettu'}</Typography>
                          <Button
                            variant="text"
                            color="inherit"
                            size="small"
                            href={`${domainUrl}/${e.data().slug}`}
                            target="_blank"
                            className="admin-button"
                            style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 4, textTransform: 'none' }}
                          >{`${domainUrl}/${e.data().slug}`}</Button>
                          <div 
                            className="admin-text"
                            style={{ maxHeight: 100, overflow: 'hidden', marginBottom: 10, fontSize: 14 }} 
                            dangerouslySetInnerHTML={{ __html: e.data().details}} 
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                  <ListItemSecondaryAction>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Tooltip 
                        title={`Kopioi linkki: ${domainUrl}/${e.data().slug}`} 
                        placement="left"
                      >
                        <CopyToClipboard 
                          text={`${domainUrl}/${e.data().slug}`}
                        >
                          <IconButton color="inherit">
                            <ShareIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </Tooltip>

                      <Tooltip title="Muokkaa tapahtuman tietoja" placement="left">
                        <IconButton color="inherit" onClick={(event) => openAddEventDialog(event, 'edit', e.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Arkistoi tapahtuma" placement="left">
                        <IconButton color="inherit" onClick={(event) => handleArchive(event, e.id)}>
                          <ArchiveIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>

      {eventDialogOpen && (
        <EventDialog
          open={eventDialogOpen}
          setOpen={setEventDialogOpen}
          refresh={refresh}
          setRefresh={setRefresh}
          type={eventDialogType}
          id={eventId}
          handleArchive={handleArchive}
        />        
      )}
    </Box>
  );
}

export default AdminView;
